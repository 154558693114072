import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { ResponseModel } from '../models/response.model';

@Injectable()
export class PackageService
{
    serverUrl: string = environment.server;

    header = {
    headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
    }

    getPackageNames(): Observable<ResponseModel> {
        return this._httpClient.get<ResponseModel>(this.serverUrl + 'web/packages', this.header);
    }
}
