import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { ResponseModel } from '../models/response.model';

@Injectable()
export class VehicleService
{
    serverUrl: string = environment.server;

    header = {
    headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }

    constructor(
        private _httpClient: HttpClient
    ) {}

    getAvailableVehiclesByPackage(packageName: string): Observable<ResponseModel> {
        return this._httpClient.get<ResponseModel>(this.serverUrl + 'web/vehicle/'+packageName, this.header);
    }
}