import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { ResponseModel } from '../models/response.model';
import {Customer} from "../models/customer.model";

@Injectable()
export class CustomerService
{
  serverUrl: string = environment.server;

  header = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  };

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient
  )
  {
  }

  saveCustomer(captchaResponse: string,customer: Customer): Observable<ResponseModel> {
    return this._httpClient.post<ResponseModel>(this.serverUrl+'web/saveCustomer',{response:captchaResponse,customer:customer},this.header);
  }
}
