import { Vehicle } from './vehicle.model';

export class PackageVehicles {

    public packageName: string;
    public vehicles: Vehicle[];

    constructor(
        packageName: string,
        vehicles: Vehicle[]
    ) {
        this.packageName = packageName;
        this.vehicles = vehicles;
    }

}