export class Customer {

  public registeredDataTime: Date;
  public firstName: string;
  public lastName: string;
  public licenseNumber: string;
  public mobileNumber: string;
  public companyName: string;
  public email: string;
  public emergencyContactName: string;
  public emergencyContactNumber: string;
  public address: string;
  public regoDue: Date;
  public registerMethod:string;
  public updateDate:Date;
  public licenseExpDate:Date;
  public dob: Date;


  constructor(registeredDataTime: Date, firstName: string, lastName: string, licenseNumber: string, mobileNumber: string, companyName: string, email: string, emergencyContactName: string, emergencyContactNumber: string, address: string, regoDue: Date, registerMethod: string, updateDate: Date, licenseExpDate: Date, dob: Date) {
    this.registeredDataTime = registeredDataTime;
    this.firstName = firstName;
    this.lastName = lastName;
    this.licenseNumber = licenseNumber;
    this.mobileNumber = mobileNumber;
    this.companyName = companyName;
    this.email = email;
    this.emergencyContactName = emergencyContactName;
    this.emergencyContactNumber = emergencyContactNumber;
    this.address = address;
    this.regoDue = regoDue;
    this.registerMethod = registerMethod;
    this.updateDate = updateDate;
    this.licenseExpDate = licenseExpDate;
    this.dob = dob;
  }
}
