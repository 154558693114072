import { Component, OnInit, AfterContentInit } from '@angular/core';
import { VehicleService } from 'src/services/vehicle.service';
import { PackageService } from 'src/services/package.service';
import { Packages } from 'src/models/packages.model';
import { PackageVehicles } from 'src/models/packageVehicle.model';
import { Vehicle } from 'src/models/vehicle.model';
import { DomSanitizer } from '@angular/platform-browser';
import { resolve } from 'url';
import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  packages: string[];
  packageVehicles: Array<PackageVehicles> = [];
  vehicles: Vehicle[];
  dafaultImgUrl = "assets/img/preview/cars/car-370x220x1-vw.png";
  packageNames: Array<String> = [];
  constructor(
    private vehicleService: VehicleService,
    private packageService: PackageService,
    private _sanitizer: DomSanitizer
  ) {
    this.loadScripts();
  }

  ngOnInit() {
    // First Load up package
    // Then send API calls to get vehicles of relavent packages
    this.packageService.getPackageNames().subscribe(response => {
      if (response.success) {
        this.packages = response.object;
        for (let i = 0; i < response.object.length; i++) {
          Promise.all([
            this.loadVehicles(response.object[i].packageName).then(data => {
              this.packageNames.push(response.object[i].packageName);
              this.packageVehicles.push(new PackageVehicles(response.object[i], data))
            })
          ])
        }

      }
    });
    console.log(this.packageVehicles);
    console.log(this.packageNames);
  }
  loadVehicles(packageName: string): any {
    return new Promise((resolve, reject) => {
      this.vehicleService.getAvailableVehiclesByPackage(packageName).subscribe(response => {
        resolve(response.object);
      }, reject);
    });
  }

  getImageUrl(imageByte: any): any {

    if (imageByte == null || imageByte == "") {
      return this.dafaultImgUrl;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imageByte);
  }

  loadScripts() {
    const dynamicScripts = [
     '../../assets/js/theme.js',
     '../../assets/js/custom.js'
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  public clickright(){

  }
}
