import { AbstractControl } from '@angular/forms';
import {environment} from "../environments/environment";

export class ValidationService {

  static emailValidator(control: AbstractControl) {
    if (control.value.length == 0 || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static alpaNumValidator(control: AbstractControl) {
    if (control.value.match(/^[a-zA-Z0-9]*$/)) {
      return null;
    } else {
      return { 'alphaNumericAllowed': true };
    }
  }

  static numberValidator(control: AbstractControl) {
    if (control.value.length == 0 || control.value.match(/^[0-9]*$/)) {
      return null;
    } else {
      return { 'numericAllowed': true };
    }
  }

  static dobValidator(control: AbstractControl) {
    let currentDate = new Date();
    if (control.value) {
      let dob = new Date(control.value);
      let dobYear = dob.getFullYear();
      let maxDobYear = currentDate.getFullYear() - environment.minAge;
      //console.log(dobYear, maxDobYear)
      if (maxDobYear < dobYear) {
        return { 'invalidDob': true };
      }
      else {
        return null
      }
    }
  }

  static presentDateValidator(control: AbstractControl) {
    let currentDate = new Date();
    if (control.value) {
      let dob = new Date(control.value);
      let dobYear = dob.getFullYear();
      let maxDobYear = currentDate.getFullYear() - environment.minAge;
      //console.log(dobYear, maxDobYear)
      if (maxDobYear < dobYear) {
        return { 'invalidDob': true };
      }
      else {
        return null
      }
    }
  }
}
