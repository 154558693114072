import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'epic-rent-a-car-website';

  contFormRegisterd: boolean = false;

  onSubmit() {
    this.contFormRegisterd = true;
  }


}
