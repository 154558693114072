import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {CustomerService} from "../../services/customer.service";
import {ResponseModel} from "../../models/response.model";
import {environment} from "../../environments/environment";
import {ValidationService} from "../../services/validation.service";
import {Customer} from "../../models/customer.model";

@Component({
  selector: 'app-customer-register-form',
  templateUrl: './customer-register-form.component.html',
  styleUrls: ['./customer-register-form.component.scss']
})
export class CustomerRegisterFormComponent implements OnInit {

  customerForm;
  captchaKey: string = environment.secret;
  formStatusValid: boolean = false;
  captchaResponse:string;
  custFormRegisterd: boolean = false;

  constructor(private formBuilder: FormBuilder, private customerService: CustomerService) {
    this.customerForm = this.formBuilder.group({
      fName: new FormControl('', [Validators.min(2), Validators.required]),
      lName: new FormControl('', [Validators.min(2), Validators.required]),
      dob: new FormControl('', [ValidationService.dobValidator,Validators.required]),
      lNo: new FormControl('', [Validators.required]),
      // lNo: new FormControl('', [ValidationService.alpaNumValidator,Validators.min(9),Validators.max(20),Validators.required]),
      lExpiry: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      cmpName: new FormControl('', null),
      emergencyContactName: new FormControl('', Validators.required),
      emergencyContactNo: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    if(this.customerForm.valid && this.captchaResponse!=null){
      let customerObj= new Customer(
        new Date(),
        this.customerForm.controls['fName'].value,
        this.customerForm.controls['lName'].value,
        this.customerForm.controls['lNo'].value,
        this.customerForm.controls['phone'].value,
        this.customerForm.controls['cmpName'].value,
        this.customerForm.controls['email'].value,
        this.customerForm.controls['emergencyContactName'].value,
        this.customerForm.controls['emergencyContactNo'].value,
        this.customerForm.controls['address'].value,
        new Date(),
        "online",
        new Date(),
        this.customerForm.controls['lExpiry'].value,
        new Date()
        );

      this.custFormRegisterd = true;

      this.customerService.saveCustomer(this.captchaResponse,customerObj).subscribe(
        response=>{
          console.log(response);
        },error=>{
          console.log(error);
        }
      );
    }
    console.warn('Customer Saved');
  }

  checkValid(formcontrolName) {
    if (this.customerForm.controls[formcontrolName].touched) {
      return !this.customerForm.controls[formcontrolName].valid;
    }
  }

  stepCheck(formcontrolNames: Array<string>) {
    let errors = 0;
    formcontrolNames.forEach(formcontrolName => {
      if (!this.customerForm.controls[formcontrolName].valid) {
        errors++;
      }
    });
    return errors != 0;
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != null) {
      this.captchaResponse = captchaResponse;
      this.formStatusValid = this.customerForm.valid;
    } else {
      this.formStatusValid = false;
    }

  }

  ngOnInit() {
    console.log(this.customerForm);
  }

}
