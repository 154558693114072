import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { PackageService } from 'src/services/package.service';
import { VehicleService } from 'src/services/vehicle.service';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerRegisterFormComponent } from './customer-register-form/customer-register-form.component';
import {ReactiveFormsModule, FormsModule} from "@angular/forms";
import {RecaptchaModule} from "ng-recaptcha";
import {CustomerService} from "../services/customer.service";
import {ValidationService} from "../services/validation.service";
@NgModule({
  declarations: [
    AppComponent,
    VehiclesComponent,
    CustomerRegisterFormComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    RecaptchaModule,
    FormsModule
  ],
  providers: [
    PackageService,
    VehicleService,
    CustomerService,
    ValidationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
